/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Flex } from "theme-ui";
import { Link } from "components/link";
import { FaFacebook, FaInstagram } from "react-icons/fa";

const SOCIAL_DATA = {
  facebook: "https://www.facebook.com/josephinesbakehouse",
  instagram: "https://www.instagram.com/josephinesbakehouse/",
};

export default function SocialIcons() {
  return (
    <Flex sx={styles.row}>
      <Link href={SOCIAL_DATA.facebook} target="_blank" sx={styles.link}>
        <FaFacebook
          size={styles.logo.size}
          color={styles.logo.facebook.color}
        />
      </Link>
      <Link href={SOCIAL_DATA.instagram} target="_blank" sx={styles.link}>
        <FaInstagram
          size={styles.logo.size}
          color={styles.logo.instagram.color}
        />
      </Link>
    </Flex>
  );
}

const styles = {
  row: {
    display: "flex",
    gap: "15px",
    alignItems: "flex-end",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  link: {
    width: "auto",
    // margin: "0 1rem",
    transition: "transform 250ms",
    display: "inline-block",
    ":hover": {
      transform: "translateY(-2px)",
    },
  },
  logo: {
    size: "30px",
    facebook: {
      color: "#4968ad",
    },
    instagram: {
      color: "#e1306c",
    },
  },
};
