/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Container, Flex, Box } from "theme-ui";
import { Link as ScrollLink } from "react-scroll";
import Logo from "components/logo";
import { DrawerProvider } from "contexts/drawer/drawer.provider";
import MobileDrawer from "./mobileDrawer";
import MENU_DATA from "./header.data";
import logo from "assets/images/jb_logotype.png";
import SocialIcons from "../social";

export default function Header({ className }) {
  return (
    <DrawerProvider>
      <header sx={styles.header} className={className}>
        <Container sx={styles.container}>
          <Logo image={logo} width={195} height={37} />

          <Flex as="nav" sx={styles.nav}>
            {MENU_DATA.map(({ path, label }, i) => (
              <ScrollLink
                activeClass="active"
                sx={styles.nav.navLink}
                to={path}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                key={i}
              >
                {label}
              </ScrollLink>
            ))}
            <Box sx={styles.social}>
              <SocialIcons />
            </Box>
          </Flex>
          <MobileDrawer />
        </Container>
      </header>
    </DrawerProvider>
  );
}

const styles = {
  header: {
    color: "text_white",
    fontWeight: "normal",
    py: [4, null, null, "25px"],
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "transparent",
    transition: "all 0.4s ease",

    "&.sticky": {
      backgroundColor: "background",
      color: "text",
      py: "15px",
      boxShadow: "0 1px 2px rgba(0, 0, 0, 0.06)",
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  social: {
    width: "auto",
    display: "inline-block",
    transform: "translateY(7px)",
  },
  nav: {
    ml: "auto",
    display: ["none", null, null, null, "block"],
    navLink: {
      fontSize: "16px",
      color: "#0F2137",
      fontWeight: "400",
      cursor: "pointer",
      lineHeight: "1.2",
      mr: "48px",
      transition: "500ms",
      ":last-child": {
        mr: "0",
      },
      "&:hover, &.active": {
        textShadow: "0 0 1px #0F2137",
      },
    },
  },
};
