/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Container, Box, Text } from "theme-ui";

import SocialIcons from "../social";

const FOOTER_DATA = {
  copyright: "Copyright © 2023 Josephine's Bakehouse",
};

const Footer = () => {
  return (
    <Box as="footer" sx={styles.footer}>
      <Container sx={styles.container}>
        <SocialIcons />
        <Text as="p">{FOOTER_DATA.copyright}</Text>
      </Container>
    </Box>
  );
};

export default Footer;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
  },
  footer: {
    pt: ["65px", null, "75px", null, "90px"],
    pb: ["25px", null, "35px", "25px", "80px"],
  },
};
