const MENU_DATA = [
  {
    path: "banner",
    label: "Home",
  },
  {
    path: "menu",
    label: "Menu",
  },
  { path: "order", label: "Order" },
  { path: "about", label: "About" },
];
export default MENU_DATA;
